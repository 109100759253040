import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: "theme.palette.secondary.light",
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 75,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
    overflow: "hidden",
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductHowItWorks(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="images/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Typography
          variant="h4"
          marked="center"
          className={classes.title}
          component="h2"
          style={{ color: " #8f2d56" }}
        >
          How to play
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number} style={{ color: "#218380" }}>
                  1.
                </div>
                <img
                  src="images/opponentDown.png"
                  alt="clock"
                  className={classes.image}
                />
                <img
                  src="images/board2.png"
                  alt="suitcase"
                  className={classes.image}
                />
                <img
                  src="images/10cards.png"
                  alt="suitcase"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                  You and your opponent are each dealt 10 cards. You only see 3
                  out of the 5 board cards ("The Flop").
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number} style={{ color: "#218380" }}>
                  2.
                </div>
                <img
                  src="images/opponentDown.png"
                  alt="clock"
                  className={classes.image}
                />
                <img
                  src="images/board2.png"
                  alt="suitcase"
                  className={classes.image}
                />
                <img
                  src="images/hands.png"
                  alt="suitcase"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                  Set your cards into 5 poker hands based on the 3 Flop cards
                  you see on the board and predicting which final two cards will
                  be dealt ("The Turn" and "The River")
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number} style={{ color: "#218380" }}>
                  3.
                </div>

                <img
                  src="images/opponentHandsScored.png"
                  alt="clock"
                  className={classes.image}
                />

                <img
                  src="images/boardFinal.png"
                  alt="suitcase"
                  className={classes.image}
                />
                <img
                  src="images/playerHandsScored.png"
                  alt="suitcase"
                  className={classes.image}
                />

                <Typography variant="h5" align="center">
                  The Turn and River are dealt and your five hands are compared
                  against your opponents. If your first hand is better than your
                  opponents you get 5 points. Then next hand is worth 4 points
                  and so on with a total of 15 points per hand available.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          className={classes.button}
          style={{ "background-color": "#8f2d56" }}
          component="a"
          href="https://playbosspoker.com/support/"
        >
          Questions?
        </Button>
      </Container>
    </section>
  );
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link
        color="inherit"
        href="https://apps.apple.com/us/app/boss-poker/id1533286682"
      >
        Findmory Development
      </Link>
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#73d2de",
    fontSize: 14,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      // height: '40vh',
      overflow: "hidden",
      width: 375,
    },
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
  },
  iconsWrapper: {
    height: 105,
    width: 375,
  },
  icons: {
    display: "flex",
  },
  icon: {
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid item>
              <Copyright />
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            {/* <Typography variant="h6" marked="left" gutterBottom>
              Legal
            </Typography> */}
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link href="https://playbosspoker.com/privacy/">Privacy</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <ul className={classes.list}>
              {/* <li className={classes.iconsWrapper}>
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/authors/freepik"
                  title="Freepik"
                >
                  Freepik
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  {" "}
                  www.flaticon.com
                </a>
              </li> */}
            </ul>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import ReactPlayer from "react-player/file";

const styles = (theme) => ({
  background: {
    backgroundColor: "#8f2d56", // Average color of the background image.
    backgroundPosition: "center",
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: "300px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "300px",
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  video: {
    position: "absolute",

    zIndex: -5,
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <ReactPlayer
        url={[{ src: "images/compressed3.mp4", type: "video/mp4" }]}
        playing={true}
        controls={false}
        loop
        volume={0}
        muted
        autoPlay
        playsinline
        alt="increase priority"
        width={1920}
        height={1080}
        className={classes.video}
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        CAN YOU BEAT THE BOSS?
      </Typography>
      <Typography
        color="inherit"
        align="center"
        className={classes.h5}
        marginBottom={50}
      ></Typography>
      {/* <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        href="https://apps.apple.com/us/app/boss-poker/id1533286682"
      >
        DOWNLOAD NOW
      </Button> */}
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
